import { getSnapshot, Instance, types } from 'mobx-state-tree'
import charge24Api from '../api/charge24'
import {
  Account,
  AccountSelectedTypeModel,
  AccountServiceModel,
  IAccount,
  IAccountSelectedType,
  IAccountSnapshotIn,
} from './models/Account'
import passportApi from '../api/passport'
import rootStore from './RootStore'
import pay24Api from '../api/pay24'
import walletApi from '../api/wallet'
import { isEmpty } from 'lodash'
import {
  ACCOUNT_TYPES,
  filterServicesByCountry,
  getServiceCountries,
  isCharge24,
  isEvion,
} from '../utils'

import i18n from '../translate/i18n'

const actions = (_self) => {
  const self = _self as IAccountStore
  return {
    async setCredit(account: IAccountSnapshotIn) {
      const id = account ? account.db_id?.toString() || account.id : ''
      return passportApi.setAccount({ type: 'credit', id }, true).then(() => {
        rootStore.user?.data.setData('default_credit', id)
      })
    },
    async setDebit(account: any) {
      let { user } = rootStore
      if (user) {
        self.changeOverrideDebit(undefined)
        return passportApi
          .setAccount(
            { type: 'debit', id: account ? account.db_id || account.id : '' },
            true,
          )
          .then(() => {
            user?.data.setData(
              'default_debit',
              account?.db_id?.toString() || account.id,
            )
          })
      } else {
        const debitsFind = self.debits.find(
          (a) => a.id === account.id && a.db_id === account.db_id,
        )
        self.changeOverrideDebit(debitsFind)
      }
    },
    async refreshDebit(service_id?, isSubAgent = false, country = 'KG') {
      let resp =
        isCharge24 || isEvion
          ? await charge24Api.getDebitAccounts({ country })
          : await pay24Api.getDebitAccounts({ isSubAgent })
      if (service_id) {
        if (service_id !== walletApi.WALLET_SERVICE_ID) {
          let r = await walletApi.getService({ service_id }, true)
          let { overdraft } = r
          if (overdraft && overdraft.limit > 0) {
            resp.push({
              platform: 'agent',
              id: 'overdraft',
              name: 'Овердрафт',
              balance: overdraft.limit,
            })
          }
        }
      }
      self.changeDebits(resp)
    },
    async refreshCredit() {
      const resp = await pay24Api.getCreditAccounts(null)
      self.changeCredits(resp)
    },
    async refreshBills() {
      let resp = await pay24Api.bill()
      self.changeBills(resp)
    },
    async payBill(bill) {
      await pay24Api.payBill(bill)
      await self.refreshBills()
    },
    onAdd(params) {
      return passportApi.addAccount(params)
    },
    getDepositableServices() {
      walletApi
        .getDepositableServices({ props: { permit_deposit: true } })
        .then((r) => {
          self.changeServices(r.services)
        })
        .finally(() => self.changeLoading(false))
    },
    setItem(item: object, isDebit: boolean) {
      if (isDebit) {
        self.changeSelectedDebit(item)
      } else {
        self.changeSelectedCredit(item)
      }
    },
    filterTypesCount(item: IAccountSelectedType) {
      if (item.id) {
        return (
          i18n.t(item.name) +
          ': ' +
          self.filterTypes.filter((type) => type.categoryid === item.id).length
        )
      }
      return i18n.t(item.name) + ': ' + self.filterTypes.length
    },
    changeBills(bills: any) {
      self.bills = bills
    },
    changeDebits(debits: any) {
      self.debits = debits
    },
    changeCredits(credits: any) {
      self.credits = credits
    },
    changeOverrideDebit(overDebit: any) {
      self._overrideDebit = overDebit ? getSnapshot(overDebit) : overDebit
    },
    chargeDebit(debit: any) {
      self._overrideDebit = debit
    },
    changeSelectedCredit(credit: any) {
      self.selectedCredit = { ...credit }
    },
    changeCryptoDebit(debit: any) {
      self.cryptoDebit = debit ? getSnapshot(debit) : debit
    },
    changeSelectedDebit(debit: any) {
      self.selectedDebit = { ...debit }
    },
    changeSelectedCreditName(val: string) {
      if (self.selectedCredit) {
        self.selectedCredit.name = val
      }
    },
    changeSelectedDebitName(val: string) {
      if (self.selectedDebit) {
        self.selectedDebit.name = val
      }
    },
    changeSelectedDebitTerminalGroup(val: string) {
      if (self.selectedDebit) {
        self.selectedDebit.group = val
      }
    },
    changeServices(services) {
      self.services = services
    },
    changeLoading(isLoading: boolean) {
      self.loading = isLoading
    },
    changeSelectedCountry(country: string) {
      self.selectedCountry = country
    },
    changeSearchText(text: string) {
      self.searchText = text
    },
    changeSelectedType(type: IAccountSelectedType) {
      self.selectedType = type
    },
    changeValue(value: string) {
      self.value = value
    },
    removeAccount(db_id) {
      return passportApi.removeAccount(db_id)
    },
    getSource(service_id) {
      return self.services.find((a) => a.id === service_id)
    },
    changeCustomer(customer: string, result = 1) {
      self.customer = customer
      self.found = result === 0
    },
  }
}
const views = (_self) => {
  const self = _self as IAccountStore
  return {
    get creditAccounts() {
      if (self.credits.length === 0) {
        self.refreshCredit()
      }
      return self.credits
    },
    get debit() {
      if (self._overrideDebit) return self._overrideDebit
      if (self.debits.length === 0) {
        return null
      }
      let d: Array<IAccount> = []
      let { user } = rootStore
      if (user?.data.default_debit) {
        if (isNaN(parseInt(user.data.default_debit))) {
          d = self.debits.filter(
            (a) => !a.db_id && a.id === user?.data.default_debit,
          )
        } else {
          d = self.debits.filter(
            (a) => a.db_id?.toString() === user?.data.default_debit,
          )
        }
      }
      if (!isEmpty(d)) {
        return d[0]
      }
      return null
    },
    get creditWired() {
      return self.credits.filter((d) => !!d.requisite)
    },
    get debitWired() {
      return self.debits.filter((d) => !!d.requisite)
    },
    get debitUnWired() {
      return self.debits.filter((d) => !d.requisite)
    },
    get countries() {
      return getServiceCountries(self.services)
    },
    get filteredServices() {
      return self.services
        .filter(
          (a) =>
            a.name &&
            a.name
              .toLocaleLowerCase()
              .includes(self.searchText.toLocaleLowerCase()),
        )
        .sort((a1, a2) => a1.name.localeCompare(a2.name))
        .filter((item) => filterServicesByCountry(item, self.selectedCountry))
        .filter((item) => {
          if (self.selectedType.id) {
            return item.categoryid === self.selectedType.id
          }
          return item
        })
    },
    get filterTypes() {
      return self.services
        .filter(
          (a) =>
            a.name &&
            a.name
              .toLocaleLowerCase()
              .includes(self.searchText.toLocaleLowerCase()),
        )
        .sort((a1, a2) => a1.name.localeCompare(a2.name))
        .filter((item) => filterServicesByCountry(item, self.selectedCountry))
        .filter((item) => {
          return item
        })
    },
    get requisiteFound() {
      return !self.found
    },
    async saveTerminalGroup(group_id: any) {
      await pay24Api
        .changeTerminalGroup({ group_id: group_id })
        .then(() => rootStore.getUserInfo())
    },
  }
}

export const AccountStoreModel = types
  .model({
    _overrideDebit: types.union(Account, types.frozen()),
    credits: types.array(Account),
    debits: types.array(Account),
    cryptoDebit: types.union(Account, types.frozen()),
    bills: types.frozen([]),
    selectedCredit: types.maybeNull(Account),
    selectedDebit: types.maybeNull(Account),
    services: types.array(AccountServiceModel),
    loading: true,
    selectedCountry: types.optional(types.string, 'Кыргызстан'),
    searchText: '',
    selectedType: types.optional(AccountSelectedTypeModel, ACCOUNT_TYPES[0]),
    value: '',
    customer: '',
    found: false,
  })
  .actions(actions)
  .views(views)
  .named('AccountStore')

interface IAccountStore extends Instance<typeof AccountStoreModel> {}

const AccountStore = AccountStoreModel.create()
export default AccountStore
