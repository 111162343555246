import { flow, getSnapshot, types } from 'mobx-state-tree'
import moment from 'moment'
import { admin, charge24 } from '../api'
import { i18n } from '../translate'
import { CURRENCIES, dateFormatDB } from '../utils'
import { editableModel } from './editable'
import { downloadExcel } from '../utils/file'

const WorkTimeWeek = types.compose(
  types.model('WorkTimeWeek', {
    time_start: types.optional(types.string, ''),
    time_end: types.optional(types.string, ''),
    all_day: types.optional(types.boolean, false),
    on: types.optional(types.boolean, true),
  }),
  editableModel(),
)

const WorkTimeModel = types.compose(
  types.model('WorkTimeModel', {
    monday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    tuesday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    wednesday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    thursday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    friday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    saturday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
    sunday: types.optional(WorkTimeWeek, () => WorkTimeWeek.create()),
  }),
  editableModel(),
)

const TariffModel = types.compose(
  types.model('TariffModel', {
    currency: types.optional(types.string, CURRENCIES.KGS.value),
    id: types.optional(types.union(types.string, types.number), 0),
    power: types.frozen(''),
    tariff_type: types.optional(types.string, 'duration'),
    premium_price: types.frozen(''),
    premium_price_in_currency: types.frozen(''),
    price: types.frozen(''),
    price_in_currency: types.frozen(''),
  }),
  editableModel(),
)

const ConnectorModel = types.compose(
  types.model('ConnectorModel', {
    id: types.union(types.number, types.string),
    name: types.string,
  }),
  editableModel(),
)

const PointModel = types.compose(
  types.model('PointModel', {
    id: types.maybeNull(types.number),
    user_id: types.maybeNull(types.number),
    number: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    brand_id: types.maybeNull(types.number),
    address: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    longitude: types.optional(types.string, ''),
    latitude: types.optional(types.string, ''),
    blocked: types.maybeNull(types.boolean),
    booking: types.maybeNull(types.boolean),
    booking_tariff: types.frozen(),
    merchant_visible: types.frozen(),
    assign_fee: types.maybeNull(types.boolean),
    can_select_merchant: types.frozen(),
    tariff: types.optional(TariffModel, () => TariffModel.create()),
    connector: types.optional(types.array(ConnectorModel), () => [
      ConnectorModel.create({ id: '', name: '' }),
    ]),
    additional_info: types.maybeNull(types.string),
    power_limit: types.frozen(0),
    auto_logout: types.maybeNull(types.boolean),
    workTime: types.optional(WorkTimeModel, () => WorkTimeModel.create()),
  }),
  editableModel(),
)

const BasedModel = types.compose(
  types.model('BasedModel', {
    id: types.string,
    name: types.string,
  }),
  editableModel(),
)

const SelectMerchant = types.compose(
  types.model('SelectMerchant', {
    evion: types.optional(types.boolean, false),
    charge24: types.optional(types.boolean, false),
  }),
  editableModel(),
)

const UserAgentDataModel = types.compose(
  types.model('UserAgentDataModel', {
    legal_naming: types.maybeNull(types.string),
    legal_address: types.maybeNull(types.string),
    bank_name: types.maybeNull(types.string),
    bank_account: types.maybeNull(types.string),
    okpo: types.maybeNull(types.string),
    gni: types.maybeNull(types.string),
    swift: types.maybeNull(types.string),
    contact_phone: types.maybeNull(types.string),
    dealer_commission: types.maybeNull(types.union(types.string, types.number)),
    country: types.maybeNull(BasedModel),
    currency: types.maybeNull(types.string),
    corporate_allowed: types.optional(types.boolean, false),
    can_select_merchant: types.optional(SelectMerchant, () =>
      SelectMerchant.create(),
    ),
  }),
  editableModel(),
)

const CorporateModel = types.compose(
  types.model('CorporateModel', {
    discount: types.optional(types.number, 0),
    payment_method: types.optional(types.string, ''),
  }),
  editableModel(),
)

const UserDataModel = types.compose(
  types.model('UserDataModel', {
    agent_data: types.optional(UserAgentDataModel, () =>
      UserAgentDataModel.create(),
    ),
    currency: types.maybeNull(types.string),
    inn: types.maybeNull(types.string),
    is_agent: types.optional(types.boolean, false),
    free_charge: types.optional(types.boolean, false),
    corporate: types.maybeNull(CorporateModel),
  }),
  editableModel(),
)

const UserModel = types.compose(
  types.model('UserModel', {
    agent_data: types.optional(UserAgentDataModel, () =>
      UserAgentDataModel.create(),
    ),
    id: types.maybeNull(types.union(types.number, types.string)),
    oauth_id: types.maybeNull(types.number),
    phone_number: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    data: types.optional(UserDataModel, () => UserDataModel.create()),
    points: types.maybeNull(types.number),
    is_agent: types.maybeNull(types.optional(types.boolean, false)),
    inn: types.maybeNull(types.string),
    avatar: types.maybeNull(types.string),
  }),
  editableModel(),
)

const actions = (self) => {
  return {
    reset() {
      self.point = undefined
      self.totalItems = 0
      self.pageSize = 25
      self.pageNumber = 1
      self.search_text = ''
      self.date_from = dateFormatDB(
        moment().subtract(2, 'weeks').startOf('month'),
      )
      self.date_to = dateFormatDB(moment())
      self.select_numbers = []
      self.pointOptions = []
      self.station = ''
      self.select_users = []
      self.recon = null
    },
    setLoading(isLoading: boolean) {
      self.loading = isLoading
    },
    clear() {
      self.roles.replace([])
    },
    getUsers: flow(function* () {
      let query = {
        search: self.search_text,
        page_size: self.pageSize,
        page_number: self.pageNumber,
      }
      let r = yield charge24.getUsers(query)
      self.users = r.data.users
      self.totalItems = r.data.items_count
      self.pageSize = r.data.page_size
      self.pageNumber = r.data.page_number
    }),
    getUser(id) {
      charge24.getUserById(id).then((res) => self.setUser(res.user[0]))
    },
    getRecon(isAdmin) {
      const data = {
        date_from: self.date_from,
        date_to: self.date_to,
        page_number: self.pageNumber,
        page_size: self.pageSize,
        agent_id: self.user_id,
        kwt_price: parseFloat(self.kwt_price || '0'),
      }
      charge24
        .getRecon(data)
        .then((res) => self.setRecon(res))
        .catch(() => {
          self.setRecon(null)
        })
      if (isAdmin) {
        charge24.getReconData(data).then((res) => {
          self.setReconData(res.items)
          self.setTotalItems(res.totalItems || 0)
        })
      }
    },
    getReportExcel(pageNumber, pageSize, isAdmin) {
      const excelApi = !isAdmin
        ? charge24.getReportExcel
        : charge24.getReportExcelAdmin
      const data = {
        date_from: self.date_from,
        date_to: self.date_to,
        numbers: self.select_numbers,
        page_number: pageNumber,
        page_size: pageSize,
        search: self.station,
        users: self.select_users,
        language: i18n.language,
      }
      excelApi(data).then((res) => {
        if (res.filename) {
          self.setFilename(res.filename)
        }
      })
    },
    getReconExcel() {
      const data = {
        date_from: self.date_from,
        date_to: self.date_to,
        page_number: self.pageNumber,
        page_size: self.pageSize,
        agent_id: self.user_id,
        kwt_price: parseFloat(self.kwt_price || '0'),
        excel: true,
      }
      charge24.getReconData(data).then((res) => {
        if (res.filename) {
          self.setFilename(res.filename)
        }
      })
    },
    async startDownload() {
      return charge24.downloadReportExcel(self.filename || '').then((res) => {
        downloadExcel(res.data, self.filename)
        self.setFilename(null)
      })
    },
    getMonitoring(pageNumber = 1) {
      const data = {
        page_number: pageNumber,
        page_size: 25,
      }
      charge24.getMonitoring(data).then((res) => self.setMonitoring(res))
    },
    runPoint(number: string) {
      charge24.runPoint({ point_number: number })
    },
    stopPoint(number: string) {
      charge24.runPoint({ point_number: number })
    },
    resetPoint(data) {
      charge24.resetPoint(data)
    },
    controlConnector(data) {
      charge24.controlConnector(data)
    },
    getFilesStation(number, connector_id) {
      const data = {
        number,
        connector_id,
      }
      charge24.getStationFiles(data).then((res) => {
        if (res.job_id) {
          const intervalId = setInterval(() => {
            const req = charge24.getFilesJobCheck(res.job_id)
            req.then((r) => {
              if (r.status === 1) {
                self.setStationFiles(r.resp)
                clearInterval(intervalId)
              }
            })
          }, 3000)
        }
      })
    },
    getCurrencies() {
      charge24.getCurrencies().then((res) => self.setCurrencies(res.rates))
    },
    getBlockedCharge: function () {
      return charge24.getBlockedPoints('off')
    },
    clearUsers: function () {
      self.users.replace([])
    },
    setUserId: function (user_id) {
      self.user_id = user_id
    },
    setSearch: function (val: string) {
      self.search_text = val
    },
    setMonitoring(data) {
      self.monitoring = data
    },
    setUsers: function (users) {
      self.users.clear()
      users.map((user) => {
        self.users.push({
          id: user.id,
          phone_number: user.username,
          name: user.name,
        })
      })
    },
    setUser(user) {
      if (user?.data?.agent_data === null) {
        user.data.agent_data = {}
      }
      self.user = user
    },
    setPoint(point) {
      self.point = getSnapshot(point)
    },
    setRecon(recon) {
      self.recon = recon
    },
    setReconData(data) {
      self.reconData = data
    },
    resetPoints() {
      self.points = []
    },
    setInn: function (inn) {
      self.inn = inn
    },
    setKwtPrice(val) {
      self.kwt_price = val
    },
    setPageSize: function (page) {
      self.pageSize = page
    },
    setTotalItems: function (items: number) {
      self.totalItems = items
    },
    setConnectorName(name, index) {
      self.point.connector[index].name = name
    },
    changeWorkTime: function (item, key, value) {
      self.point.workTime[item[0]].setValue(key, value)
    },
    setPageNumber: function (number: number) {
      self.pageNumber = number
    },
    setDateFrom(date) {
      self.date_from = dateFormatDB(date)
    },
    setSelectUsers(users) {
      self.select_users = users
    },
    setSelectNumbers(numbers) {
      self.select_numbers = numbers
    },
    setDateTo(date) {
      self.date_to = dateFormatDB(date)
    },
    setStation(station) {
      self.station = station
    },
    setBrand(brand) {
      self.brand = brand
    },
    setCurrencies(currencies) {
      self.currencies = currencies
    },
    setPointOptions(options) {
      self.pointOptions = options
    },
    setStationFiles(files: any) {
      self.stationFiles = files
    },
    addConnectorItem: function () {
      if (self.point.connector.length < 2) {
        self.point.connector.push({ id: '', name: '' })
      }
    },
    setFilename(filename: string | null) {
      self.filename = filename
    },
    removeConnectorItem: function (element_index) {
      const connectors = self.point.connector.filter(
        (_, index) => index !== element_index,
      )
      self.point.setValue('connector', connectors)
    },
    resetUserId: function () {
      self.user_id = 0
    },
    getPoints: flow(function* (data) {
      self.setLoading(true)
      let query = {
        users: data,
      }
      try {
        let r = yield charge24.getPoints(query)
        self.points.replace(r.points)
      } finally {
        self.setLoading(false)
      }
    }),
    toggleRole: flow(function* (role) {
      const user = self.users?.find((u) => u.id === self.user_id)
      let r = yield admin.saveUserRole(user?.id, role.id)

      if (r.roles) {
        user.roles.replace(r.roles)
      }
    }),
    getSystemTypes: function () {
      return [
        { id: 'CHRG_USER', name: 'chargeusers', path: 'chargeusers' },
        { id: 'CHRG_POINT', name: 'points', path: 'points' },
      ]
    },
  }
}

const views = (_self) => {
  const self = _self // as ISystemStore
  return {
    get isPageValid() {
      return (
        self.page &&
        self.page.id &&
        self.page.name &&
        // && ((self.page.is_group && !self.page.path) || (!self.page.is_group && self.page.path))
        self.page.system
      )
      // && self.page.icon
    },
    get selectedUser() {
      return self.users?.find((u) => u.id === self.user_id)
    },
    hasAccessInRole(page_id, access) {
      const role = self.rolePages?.find(
        (rp) => rp.role_id === self.role_id && rp.page_id === page_id,
      )
      if (role) {
        return role[access]
      }
      return false
    },
  }
}

const volatile = () => {
  return {
    totalItems: 0,
    pageSize: 25,
    pageNumber: 1,
    search_text: '',
    date_from: dateFormatDB(moment().subtract(2, 'weeks').startOf('month')),
    date_to: dateFormatDB(moment()),
    select_numbers: [],
    station: '',
    select_users: [],
    kwt_price: '',
  }
}

const Connectors = types.model('Connectors', {
  connector_id: types.maybeNull(types.union(types.number, types.string)),
  data: types.frozen(),
  status: types.maybeNull(types.string),
})

const Point = types.model('Point', {
  data: types.frozen(),
  connectors: types.array(Connectors),
  id: types.maybeNull(types.union(types.number, types.string)),
  status: types.maybeNull(types.string),
  total_price: types.optional(types.union(types.string, types.number), 0),
  spent_price: types.optional(types.union(types.string, types.number), 0),
  total_result_KwT: types.optional(types.union(types.string, types.number), 0),
  transaction_count: types.optional(types.union(types.string, types.number), 0),
  total_charging_time: types.optional(
    types.union(types.string, types.number),
    0,
  ),
  number: types.maybeNull(types.string),
})

const Monitoring = types.model('Monitoring', {
  ttotal_price: types.optional(types.number, 0),
  ttotal_result_KwT: types.optional(types.number, 0),
  count_transaction: types.optional(types.number, 0),
  active: types.optional(types.number, 0),
  inactive: types.optional(types.number, 0),
  count: types.optional(types.number, 0),
  charge_points: types.array(Point),
})

const Recon = types.model('Recon', {
  all_amount: types.optional(types.number, 0),
  charge24_fee: types.optional(types.number, 0),
  currency: types.string,
  date_from: types.string,
  date_to: types.string,
  electricity: types.optional(types.number, 0),
  free_amount: types.optional(types.number, 0),
  dealer_commission: types.maybeNull(types.union(types.string, types.number)),
  total_receivable: types.optional(types.number, 0),
  netto: types.optional(types.number, 0),
  total_kwt: types.optional(types.number, 0),
})

const ReconData = types.model('ReconData', {
  date: types.string,
  totalCount: types.optional(types.number, 0),
  totalFreeSum: types.optional(types.number, 0),
  totalKwt: types.optional(types.number, 0),
  totalSum: types.optional(types.number, 0),
  totalTime: types.optional(types.number, 0),
})

export const Charge24StoreModel = types
  .model('Charge24StoreModel', {
    inn: types.optional(types.string, ''),
    points: types.optional(types.array(PointModel), []),
    point: types.optional(PointModel, () => PointModel.create()),
    monitoring: types.maybeNull(Monitoring),
    pointOptions: types.optional(types.array(PointModel), []),
    user_id: types.maybeNull(types.union(types.string, types.number)),
    users: types.optional(types.array(UserModel), []),
    currencies: types.array(
      types.model({ id: types.string, name: types.string }),
    ),
    user: types.optional(UserModel, () => UserModel.create()),
    brand: types.maybeNull(types.frozen()),
    stationFiles: types.frozen(),
    recon: types.maybeNull(Recon),
    reconData: types.array(ReconData),
    loading: false,
    filename: types.maybeNull(types.string),
  })
  .volatile(volatile)
  .actions(actions)
  .views(views)
  .named('Charge24Store')

const Charge24Store = Charge24StoreModel.create()
export default Charge24Store
